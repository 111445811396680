import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/forge/docs.wild.plus/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Link } from "docz";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "styled"
    }}>{`Styled`}</h1>
    <p>{`A collection of full accessible, tested and performant UI components made by wild.`}</p>
    <h2 {...{
      "id": "theming"
    }}>{`Theming`}</h2>
    <div>
  Many components can be styled and extended using the styled-component's theming feature. It is therefore{" "}
  <strong>required</strong>, to setup a `ThemeProvider` with the <Link to="/styled/utils#theme" mdxType="Link">default theme</Link> as
  a base, which can be extended as necessary.
    </div>
    <br />
    <ol>
      <li parentName="ol">{`Create a theme provider and extend the theme as needed.`}</li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-tsx"
      }}>{`import { theme as defaultTheme } from "@madebywild/styled-utils";

const theme = {
  ...defaultTheme,
  screens: {
    ...defaultTheme.screens,
    "3xl": 2000,
  }
}

<ThemeProvider theme={theme}>
  <App />
</ThemeProvider>
`}</code></pre>
    <div>
  2. Create a new declaration file `styled-components.d.ts` in a place that is accessible by the TS compiler (the `src`
  directory is usually fine). In case you extended the base theme, you should modify the interface accordingly. Keep in
  mind that general TS rules apply when extending an interface.
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`import "styled-components";
import { Theme } from "@madebywild/styled-utils";

declare module "styled-components" {
  // 1. Extend styled-components with our base theme.
  export interface DefaultTheme extends Theme {
    // 2. Optionally, add custom tokens according to your theme.
    screens: Theme["screens"] & {
      3xl: number;
    };
  }
}
`}</code></pre>
    <h2 {...{
      "id": "components"
    }}>{`Components`}</h2>
    <ul>
      <li parentName="ul">
        <Link to="/styled/grid" mdxType="Link">Grid</Link>
        <div>
  <small>Grid component based on the CSS Grid Layout spec.</small>
        </div>
      </li>
      <li parentName="ul">
        <Link to="/styled/grid.item" mdxType="Link">Grid Item</Link>
        <div>
  <small>Grid item component, it must be used as direct children of Grid.</small>
        </div>
      </li>
      <li parentName="ul">
        <Link to="/styled/utils" mdxType="Link">Utils</Link>
        <div>
  <small>A collections of utils, used across Styled packages.</small>
        </div>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      